<template>
  <div>
    <v-dialog
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="Delete"
      max-width="450px"
      max-height="800px"
    >
      <v-card style="background-color: #f2f2f2">
        <v-card-title
          class="headline darken-1"
          style="background-color: #145c9f; color: white; height: auto"
        >
          <div class="d-flex px-1">
            <v-img height="80" width="80" src="@/assets/warning.svg"></v-img>
          </div>
          <div style="font-family: Roboto Slab" class="d-flex px-5">
            Are you sure want to <br />
            delete?
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            color="white"
            style="margin-top: -27%; margin-left: 94%"
            @click="closeCallBack"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="py-4">
          <v-row>
            <v-col class="px-3" cols="12">
              <p style="font-size: 16px">
                This is a irreversible action and once it's deleted, It'll be
                gone forever
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0 pb-7 px-7">
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg dialogAction-btnText text-capitalize py-5 px-7"
            dark
            color="#D30024"
            @click="deleteTeacher"
            :loading="formLoading"
          >
            <span>Yes Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { API_ADMIN_TEACHER_MANAGEMENT_DELETE } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "DeleteTeacherConfirmation.vue",
  data() {
    return {
      formLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "teacherManagement/getDelete",
    }),
    Delete: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleDeleteTeacherModal({ Delete: value });
      },
    },
  },
  watch: {
    Delete(value) {
      console.log(value, "watching");
    },
  },
  methods: {
    ...mapActions({
      toggleDeleteTeacherModal: "teacherManagement/toggleDeleteModal",
      showToast: "snackBar/showToast",
    }),
    closeCallBack() {
      this.toggleDeleteTeacherModal({ Delete: false });
    },
    deleteTeacher() {
      this.formLoading = true;
      console.log("deleted");
      console.log(this.$store.state.teacherManagement.deleteModal.teacherID);

      const successHandler = (res) => {
        const data = res.data;
        console.log(data);
        this.formLoading = false;
        this.toggleDeleteTeacherModal({ Delete: false });
        this.$emit("reload");
        this.showToast({
          message: "Teacher record deleted.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
        this.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["teacher_id"] =
        this.$store.state.teacherManagement.deleteModal.teacherID;
      console.log(formData);
      Axios.request_DELETE(
        API_ADMIN_TEACHER_MANAGEMENT_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
};
</script>
<style scoped>
.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
